import clsx from 'clsx'
import { SharedTypographyProps } from './common'

/**
 * Label/CTA - Medium
 */

export function LabelCtaMedium({ children, color, tag, className }: SharedTypographyProps) {
  const Tag = tag || 'div'
  return (
    <Tag
      className={clsx(
        `font-ibmPlexSans text-sm font-semibold leading-[18px] tracking-tight transition`,
        color && `text-${color}`,
        className
      )}
    >
      {children}
    </Tag>
  )
}
